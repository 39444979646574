import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../constants";

function Contact() {
  const navigate = useNavigate();

  // contact us
  const [emptyData, setEmptyData] = useState({
    name: "",
    email: "",
    mobile: "",
    reason: "",
    message: "",
  });
  const [formData, setFormData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(`${API_URL + "contactpage"}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setFormData(emptyData);
      })
      .catch((err) => {
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  return (
    <div>
      <div id="tmcs">
        <div className="container">
          <div class="row mt-5">
            <div class="col-md-4">
              <div class="card">
                <div className="m-4">
                  <h5>Address</h5>
                  <p>
                    Opp. Rythu Bazar, Ilisipuram Junction
                    
                    Srikakulam - 532001.
                    <br/>
                   Timings 7:00 AM - 9:00 PM
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card">
                <div className="m-4">
                  <h5>Reception</h5>
                  <p>
                    <i class="fas fa-phone"></i>&nbsp; Reception: +91 7581544797
                    <br />
                    <i class="fas fa-phone"></i>&nbsp; Landline: 04942-294093
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div className="m-4">
                  <h5>Medical Center Reception</h5>
                  <p>
                    <i class="fas fa-phone"></i> &nbsp; 08942-278484, +91
                    8886717486
                  <br/>
                    <i class="fas fa-envelope"></i> &nbsp; Email:
                    tmc.srikakulam@gmail.com
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bookappointment my-4">
            <div className="row">
              <h2 className="font-weight-bold px-4 py-2 text-dark text-center mb-3">
                Contact us
              </h2>
              <div className="col-md-8">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1178.286005139256!2d83.89757364355147!3d18.305866122532098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3c1518bc53ae73%3A0xcd1785b645edbb7!2sTMC&#39;s%20TIRUMALA%20HOSPITAL%2C%20SRIKAKULAM%20-%20INDIA.!5e0!3m2!1sen!2sin!4v1709689974960!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="col-md-4">
                <div className="formSubmit">
                  <p>We Are Ready To Help You With A Smile!</p>
                  <h4 className="font-weight-bold">
                    <i className="fa fa-cell"></i>Call Us:{" "}
                    <span className="colorora">+91 7581 544 797</span>
                  </h4>

                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      placeholder="Fullname*"
                      name="fullname"
                      id="fullname"
                      value={formData ? formData.name : ""}
                      onChange={handleChange}
                      className="form-control mb-3"
                    />

                    <input
                      type="text"
                      placeholder="Mobile*"
                      value={formData ? formData.name : ""}
                      onChange={handleChange}
                      id="mobile"
                      name="mobile"
                      className="form-control mb-3"
                    />

                    <input
                      type="email"
                      placeholder="Email*"
                      name="email"
                      id="email"
                      value={formData ? formData.name : ""}
                      onChange={handleChange}
                      className="form-control mb-3"
                    />

                    <select
                      className="form-control mb-3"
                      id="reason"
                      name="reason"
                      value={formData ? formData.reason : ""}
                      onChange={handleChange}
                    >
                      <option>--select problem--</option>
                      <option value="MS-SURGEON">MS SURGEON</option>
                              <option value="GASTRO">GASTRO</option>
                              <option value="GYNAIC">GYNAIC</option>
                              <option value="ORTHO">ORTHO</option>
                              <option value="ENT">ENT</option>
                    </select>
                    <div className="my-3">
                      <textarea
                        id="message"
                        name="message"
                        value={formData ? formData.name : ""}
                        onChange={handleChange}
                        placeholder="Please tell us your conern in detail"
                        className="form-control my-3"
                      ></textarea>
                    </div>
                    <div className="my-3">
                      <button className="bookapp my-4 w-100">
                        Book Appointment
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
