import React from "react";
import Shieldpic from "../../assets/sheildpic.png";
import CallIcon from "@mui/icons-material/Call";
import Button from "@mui/material/Button";
import Madampic from "../../assets/madampic.png";
import M1 from "../../assets/gallery/(11).JPG";
import M2 from "../../assets/gallery/(2).JPG";
import M3 from "../../assets/gallery/(3).JPG";
import M4 from "../../assets/gallery/(4).JPG";
import M5 from "../../assets/gallery/(5).JPG";
import M6 from "../../assets/gallery/(6).JPG";
import M7 from "../../assets/gallery/(7).JPG";
import M8 from "../../assets/gallery/(8).JPG";
function Aboutclinic() {
  return (
    <div>
      <div>
        <div className="container-fluid p-0 m-0">
          <div className="hspbanner">
              <div className="drdetails">
                <div className="container">
                  <div className="col-auto col-md-10 my-4">
                    <nav aria-label="breadcrumb" className="second">
                      <ol className="breadcrumb indigo lighten-6">
                        <li className="breadcrumb-item font-weight-bold ">
                          <a
                            className="black-text text-uppercase bread_a"
                            href="#"
                          >
                            <span className="mr-md-3 mr-2 ">Home</span>
                          </a>
                          <i
                            className="fa fa-angle-double-right text-white"
                            aria-hidden="true"
                          />
                        </li>
                        <li className="breadcrumb-item font-weight-bold">
                          <a
                            className="black-text text-uppercase bread_a"
                            href="#"
                          >
                            <span className="mr-md-3 mr-2">About Us</span>
                          </a>
                          <i
                            className="fa fa-angle-double-right text-uppercase text-white"
                            aria-hidden="true"
                          />
                        </li>
                        <li className="breadcrumb-item font-weight-bold">
                          <a
                            className="black-text text-uppercase bread_a active-2 text-white"
                            href="#"
                          >
                            <span>About TMC’S TIRUMALA HOSPITAL</span>
                          </a>
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="madamdetails pmsdy-4">
                    <h4 className="nameDr blueone">
                      TMC’S TIRUMALA HOSPITAL
                    </h4>
                    <p>10 Years Experience Overall</p>

                    <div className="ratings my-3">
                      <div className="starrate">
                        <i className="fa fa-star starsize box-shadow"></i>
                        <span className="fontwave">5</span>
                      </div>
                      <div className="mr-4">
                        <img src={Shieldpic} />
                        <span> Verified & Most Trusted One</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>

          <div className="container-fluid px-0">
            <div className="border-bottom1 ">
              <div className="container">
                <div className="mainTabs">
                  <a href="#Aboutdr" className="b_anchor inactive">
                    About
                  </a>
                  <a href="#Photos" className="b_anchor inactive">
                    Photos
                  </a>
                  <a href="#Reviews" className="b_anchor inactive">
                    Reviews
                  </a>
                  <a href="#Doctor" className="b_anchor inactive">
                    Doctor
                  </a>
                </div>

                <div className="TabsContent">
                  <div id="Aboutdr">
                    <div className="et_pb_column et_pb_column_4_4 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">
                      <div className="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light">
                        <div className="et_pb_text_inner">
                          <h1>
                            <strong>
                              About TMC’S TIRUMALA HOSPITAL
                            </strong>
                          </h1>
                        </div>
                      </div>
                      <div className="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">
                        <div className="et_pb_text_inner">
                        <div class="container">
 

    <div className="mt-3">
        <p>Welcome to TMC's Tirumala Hospital, a distinguished healthcare institution committed to delivering high-quality medical services. Our mission is to enhance the health and well-being of our community by providing compassionate and comprehensive care.</p>
    </div>

    <div>
        <h2>Services</h2>
        <ul class="list-unstyled">
            <li className="mb-3"><strong>MS Surgeon:</strong> Explore the forefront of surgical precision with our MS Surgeon technology. This cutting-edge approach combines robotics and machine learning to ensure optimal outcomes for a range of medical procedures.</li>
            <li className="mb-3"><strong>Gastro:</strong> Our gastroenterology services leverage advanced technologies for the diagnosis and treatment of various digestive disorders. We strive to provide accurate and effective solutions to improve the digestive health of our patients.</li>
            <li className="mb-3"><strong>Gynaecology:</strong> Offering comprehensive women's health services, our Gynaecology department provides expert care for a variety of gynecological conditions and reproductive health concerns.</li>
            <li className="mb-3"><strong>Orthopaedics:</strong> Specializing in musculoskeletal conditions, injuries, and joint-related issues, our Orthopaedics department is dedicated to providing personalized and effective orthopedic care.</li>
            <li className="mb-3"><strong>ENT (Ear, Nose, and Throat):</strong> Our ENT services cover diagnostics, surgeries, and treatments for a wide range of ear, nose, and throat issues. Our experienced team of specialists ensures the best possible care for our patients.</li>
            <li className="mb-3"><strong>Tirumala Lab:</strong> Our state-of-the-art laboratory services are designed to deliver accurate and timely diagnostic results. We prioritize precision in testing to enable informed medical decisions.</li>
        </ul>
    </div>

    <div>
        <p class="">At TMC's Tirumala Hospital, we are devoted to offering a holistic healthcare experience. Our team of dedicated professionals is committed to addressing the diverse needs of our patients with compassion and expertise.</p>

        <p>For appointments and inquiries, please contact us at <strong>(+91 7581 544 797)</strong>.</p>
    </div>
</div>

                        </div>
                      </div>
                    </div>

                    <div className="contactNow">
                      <div className="contactTExt">
                        <h5>
                          Reach us by <br />
                          contacting our support team!
                        </h5>
                      </div>

                      <div className="contact_section d-flex flex-direction-row justify-content-center align-items-center">
                        <div className="callsection">
                          <div className="phoneImg">
                            <CallIcon className="phonewidth" />
                          </div>
                          <div className="callus">
                            <p className="py-0 my-0 text-left1">Call Us</p>
                            <h5 className="py-0 my-0">+91-7581 544 797</h5>
                          </div>
                        </div>

                        <div className="bookappointment">
                          <a href="Contact"><Button variant="contained" className="radiusBtn">
                            Book Appointment
                          </Button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div id="Photos">
                    <div>
                      <div className="mt-4 p-4">
                        <div className="gview">
                          <h3>Clinic Infrastructure Images</h3>
                        </div>
                        <div className="container-fluid">
                          <div className="row g-0">
                            <div className="col-lg-3 col-md-4 mb-1">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M1} className="galelry-lightbox">
                                    <img
                                      src={M1}
                                      className="img-fluid rounded"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mb-1">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M2} className="galelry-lightbox">
                                    <img src={M2} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mb-1">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M3} className="galelry-lightbox">
                                    <img src={M3} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mb-1">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M4} className="galelry-lightbox">
                                    <img src={M4} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mb-1">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M5} className="galelry-lightbox">
                                    <img src={M5} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mb-1">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M6} className="galelry-lightbox">
                                    <img src={M6} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mb-1">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M7} className="galelry-lightbox">
                                    <img src={M7} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mb-1">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M8} className="galelry-lightbox">
                                    <img src={M8} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="Doctor">
                    <div className="mt-4 bgone">
                      <h2 className="font-weight-bold px-4 py-2 text-dark">
                        About TMC’S TIRUMALA HOSPITAL
                      </h2>
                      <div className="ddam-flex">
                        <div className="flexdear">
                          <div className="madampic">
                            <img
                              src={Madampic}
                              alt=""
                              className="madampicsize"
                            />
                          </div>

                          <div className="madamdetails pmsdy-4">
                            <h4 className="nameDr">TMC’S TIRUMALA HOSPITAL</h4>

                            <div className="ratings my-3">
                              <div className="starrate">
                                <i className="fa fa-star starsize"></i>
                                <span className="fontwave">5</span>
                              </div>
                              <div className="mr-4">
                                <img src={Shieldpic} />
                                <span className="font-weight-bold">
                                  {" "}
                                  Verified & Most Trusted One
                                </span>
                              </div>
                            </div>

                            <p>
                              <span className="font-weight-bold">
                                TMC’S TIRUMALA HOSPITAL{" "}
                              </span>
                              TMC's Tirumala Hospital, a distinguished healthcare institution committed to delivering high-quality medical services. Our mission is to enhance the health and well-being of our community by providing compassionate and comprehensive care.
                            </p>

                            <div className="knowmore my-4">
                              <a href="" className="knowmore_cls">
                                Know more
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  
                  <div id="Reviews">
                    <div className="review_bg">
                      <div className="mt-4 p-4">
                        <div className="gview">
                          <h3>Google Reviews</h3>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="card min335 p-3">
                              <div className="stars">
                                <div className="start_cls">
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                </div>

                                <div className="user">
                                  <h4>Gayatri sakhi</h4>
                                </div>
                              </div>

                              <div className="customerimg my-3">
                                <p>
                                  I recently visited the clinic of Dr.
                                  Padmanabha Varma's Harmone India.
                                </p>
                                <p>
                                  During my appointment, Doctor took the time to
                                  listen attentively to my medical concerns and
                                  asked thoughtful questions to understand my
                                  medical history thoroughly.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="card min335 p-3">
                              <div className="stars">
                                <div className="start_cls">
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                </div>

                                <div className="user">
                                  <h5>Radhika</h5>
                                </div>
                              </div>

                              <div className="customerimg my-3">
                                <p>
                                  We resently migrated to Srikakulam n we were
                                  looking out for Endocrinologist for thyroid
                                  problem. Researched in Google n found him near
                                  by. Called him in the evening n next day
                                  morning sharp 8am lab technicians came home to
                                  pick the sample.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="card min335 p-3">
                              <div className="stars">
                                <div className="start_cls">
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                </div>

                                <div className="user">
                                  <h5>Mohammed Salam</h5>
                                </div>
                              </div>

                              <div className="customerimg my-3">
                                <p>
                                  Dr Varma garu treatment is good , gives cost
                                  effective medication
                                </p>
                                <p>
                                  Explains what is what in simple and easy to
                                  understand way{" "}
                                </p>

                                <p>Happy with the service</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutclinic;
