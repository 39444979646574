import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./media.css";
const ImageLightbox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);

  const openLightbox = (index) => {
    setIsOpen(true);
    setPhotoIndex(index + 1);

    const timer = setTimeout(() => {
      setPhotoIndex((prevIndex) => (prevIndex - 1) % images.length);

      clearTimeout(timer);
    }, 1000); 
  };

  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const imageUrls = importAll(
      require.context("../assets/gallery", false, /\.JPG$/)
    );
    setImages(Object.values(imageUrls));
  }, []);

  const closeLightbox = () => {
    setIsOpen(false);
  };

  return (
    <>
      <h3 className="text-center mt-5">Image Gallery</h3>
      <div className="row container-fluid">
        {images &&
          images.map((image, index) => (
            <div className="col-md-3" key={index}>
              <img
                className="m-5 showingImage"
                key={index}
                src={image}
                alt={`Image ${index + 1}`}
                onClick={() => openLightbox(index)}
              />
            </div>
          ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
};

export default ImageLightbox;
