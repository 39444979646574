import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useEffect } from "react";
import PCod from "../../assets/Gyanecology/PCOD.jpg";
import $ from "jquery";
import Gynaecology from "../../assets/Gyanecology/Gynaecology.jpg";
import Endometriosis from "../../assets/Gyanecology/Endometriosis.jpg";
import Menstrual from "../../assets/Gyanecology/Menstrual-Problems.jpg";
import Blog1 from "../../assets/Gyanecology/blog1.png";
import Blog2 from "../../assets/Gyanecology/blog2.png";
import Blog3 from "../../assets/Gyanecology/blog3.png";
import Madampic from "../../assets/madampic.png";
import Shieldpic from "../../assets/sheildpic.png";
import Cervicalcancer1 from "../../assets/all/Cervical-Cancer.jpg";
import axios from "axios";
import { useState } from "react";
import Parser from "html-react-parser";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../assets/loader/loader.gif";
import YoutubeVideos from "../YoutubeVideos";
import { API_URL } from "../constants";

function Dynamic_page() {
  const [isLoading, setIsLoading] = useState(false);
  const [page_data, setPage_data] = useState();

  // submit contact

  const [loadingBook, setLoadingBook] = useState(false);
  const [formData, setFormData] = useState({});

  const [emptyData, setEmptyData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    message: "",
    reason: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingBook(true);
    axios
      .post(`${API_URL + "contactpage"}`, formData)
      .then((res) => {
        toast.success(res.data.message);
        setFormData(emptyData);
        setLoadingBook(false);
      })
      .catch((err) => {
        setLoadingBook(false);
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  // end submit contact

  var url = document.URL;
  const location = useLocation();
  var page_name = url.substr(url.lastIndexOf("/") + 1);
  
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${API_URL + "dynamic_pages/" + page_name}`)
      .then(function (response) {
        setPage_data(response.data);
        setIsLoading(false);
      });

    $(document).ready(function () {
      $(".b_anchor").click(function () {
        $(".b_anchor").removeClass("b_active").addClass("inactive");
        $(this).removeClass("inactive").addClass("b_active");
      });

      $(".accordion-toggle").on("click", function (event) {
        event.preventDefault();
        // create accordion variables
        var accordion = $(this);
        var accordionContent = accordion.next(".accordion-content");

        // toggle accordion link open class
        accordion.toggleClass("open");
        // toggle accordion content
        accordionContent.slideToggle(250);
      });
    });
  }, [location]);

  if (page_data) {
    return (
      <div>
        <div className="main-area">
          <div className="container">
            <div className="breadcrums py-4">
              <div className="row pt-4">
                <div className="col-auto col-md-10">
                  <nav aria-label="breadcrumb " className="second">
                    <ol className="breadcrumb indigo lighten-6 first">
                      <li className="breadcrumb-item font-weight-bold ">
                        <a
                          className="black-text text-uppercase bread_a"
                          href="#"
                        >
                          <span className="mr-md-3 mr-2">Home</span>
                        </a>
                        <i
                          className="fa fa-angle-double-right "
                          aria-hidden="true"
                        />
                      </li>
                      <li className="breadcrumb-item font-weight-bold">
                        <a
                          className="black-text text-uppercase bread_a"
                          href="#"
                        >
                          <span className="mr-md-3 mr-2">
                            {page_data.title}
                          </span>
                        </a>
                        <i
                          className="fa fa-angle-double-right text-uppercase "
                          aria-hidden="true"
                        />
                      </li>
                      <li className="breadcrumb-item font-weight-bold">
                        <a
                          className="black-text text-uppercase bread_a active-2"
                          href="#"
                        >
                          <span>{page_data.title}</span>
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>

                <h1 className="Title_header">{page_data.title}</h1>
              </div>
            </div>
          </div>

          <div className="container-fluid px-0">
            <div className="border-bottom1 ">
              <div className="container">
              {page_name !== 'prashanth' && page_name !== 'thirumala-lab' && (
                  <div className="mainTabs">
                      <a href="#About" className="b_anchor inactive">
                          About
                      </a>
                      <a href="#tmcs" className="b_anchor inactive">
                          TMC’S TIRUMALA HOSPITAL
                      </a>
                  </div>
              )}
                <div className="TabsContent">
                  <div id="About">
                    <div className="mt-4">
                      <div className="diseas_about">
                        <div className="row">
                          <div className="tabs_New">
                            {Parser(page_data.content)}
                            {page_data.images && page_data.images !== "none" ? (
                              <img src={page_data.images} alt="Image" />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div id="tmcs">
                    <div className="mt-4 bgone">
                  
                      <div className="ddam-flex">
                        <div className="flexdear">
                          <div className="madampic">
                            <img
                              src={Madampic}
                              alt="TMC’S TIRUMALA HOSPITAL"
                              className="madampicsize"
                            />
                          </div>

                          <div className="madamdetails pmsdy-4">
                            <h4 className="nameDr">TMC’S TIRUMALA HOSPITAL</h4>

                            <div className="ratings my-3">
                              <div className="starrate">
                                <i className="fa fa-star starsize"></i>
                                <span className="fontwave">5</span>
                              </div>
                              <div className="mr-4">
                                <img src={Shieldpic} />
                                <span className="font-weight-bold">
                                  {" "}
                                  Verified & Most Trusted One
                                </span>
                              </div>
                            </div>

                            <p>
                              <span className="font-weight-bold">
                                TMC’S TIRUMALA HOSPITAL
                              </span>
                              is a well-known name in the field of
                              endocrinology. He is trained in one of the best
                              institutes in India and worked under reputed
                              doctors in the field of endocrinology. He has over
                              10 years of experience in treating all types of
                              diabetes patients and complex hormonal problems.
                              He has earned many gold medals, delivered lectures
                              across India. He regularly conducted free diabetes
                              and thyroid camps for people of rural background{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bookappointment my-4">
                    <div className="row">
                      <div className="col-md-8">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1178.286005139256!2d83.89757364355147!3d18.305866122532098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3c1518bc53ae73%3A0xcd1785b645edbb7!2sTMC&#39;s%20TIRUMALA%20HOSPITAL%2C%20SRIKAKULAM%20-%20INDIA.!5e0!3m2!1sen!2sin!4v1709689974960!5m2!1sen!2sin"
                          width="750"
                          height="550"
                          style={{ border: "0" }}
                          allowFullScreen
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        />
                      </div>

                      <div className="col-md-4">
                        <div className="formSubmit">
                          <p>We Are Ready To Help You With A Smile!</p>
                          <h4 className="font-weight-bold">
                            <i className="fa fa-cell"></i>Call Us:{" "}
                            <span className="colorora">+91 7581 544 797</span>
                          </h4>

                          <form onSubmit={handleSubmit}>
                            <input
                              type="text"
                              placeholder="Fullname*"
                              name="fullname"
                              id="fullname"
                              value={formData ? formData.fullname : ""}
                              onChange={handleChange}
                              className="form-control mb-3"
                            />

                            <input
                              type="number"
                              placeholder="Mobile*"
                              name="mobile"
                              id="mobile"
                              value={formData ? formData.mobile : ""}
                              onChange={handleChange}
                              className="form-control mb-3"
                            />

                            <input
                              type="email"
                              placeholder="Email*"
                              name="email"
                              id="email"
                              value={formData ? formData.email : ""}
                              onChange={handleChange}
                              className="form-control mb-3"
                            />

                            <select
                              className="form-control mb-3"
                              name="reason"
                              id="reason"
                              onChange={handleChange}
                              value={formData ? formData.reason : ""}
                            >
                              <option>--Select Reason--</option>

                              <option value="MS-SURGEON">MS SURGEON</option>
                              <option value="GASTRO">GASTRO</option>
                              <option value="GYNAIC">GYNAIC</option>
                              <option value="ORTHO">ORTHO</option>
                              <option value="ENT">ENT</option>
                            </select>
                            <div className="my-3">
                              <textarea
                                placeholder="Please tell us your concern in detail *"
                                className="form-control my-3"
                                name="message"
                                id="message"
                                value={formData ? formData.message : ""}
                                onChange={handleChange}
                              ></textarea>
                            </div>

                            <div className="gapivvu text-center">
                              {loadingBook ? (
                                <img src={loader} style={{ height: "100px" }} />
                              ) : (
                                <button
                                  type="submit"
                                  className="bookapp  w-100"
                                >
                                  Book Appointment
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <span>Loading</span>;
  }
}
export default Dynamic_page;
