import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "./App.css";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Contact from "./Components/Aboutus/Contact";
import Aboutclinic from "./Components/Aboutus/Aboutclinic";
import Reviews from "./Components/PatientGuide/Reviews";
import ImageLightbox from "./Components/Images";
import "./Components/Navbar.css";
import Dynamic_page from "./Components/Dynamicpage/Dynamicpage";
import { useEffect, useState } from "react";

function openNav() {
  document.getElementById("mySidebar").style.width = "250px";
  document.getElementById("main").style.marginLeft = "250px";
}

function closeNav() {
  document.getElementById("mySidebar").style.width = "0";
  document.getElementById("main").style.marginLeft = "0";
}

function App() {
  const [isMobile, setIsMobile] = useState(false);
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }; // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const location = useLocation();
  const [routeMatched, setRouteMatched] = useState(false);

  useEffect(() => {
    const currentRoute = location.pathname;

    // Check if the current route matches and remove CSS if it does
    if (currentRoute === "/Gallery") {
      setRouteMatched(true);
    } else {
      // Add CSS if the current route does not match
      setRouteMatched(false);
    }
  }, [location]);

  return (
    <div className="App">
      <Header />
      {isMobile ? (
        <>
          <div id="mySidebar" className="sidebar">
            <a
              href="javascript:void(0)"
              className="closebtn"
              onClick={closeNav}
            >
              ×
            </a>
            <ul className="ul-nav1">
              <li className="nav-link">
                <Link to="/">Home</Link>
              </li>

              <li className="nav-link">
                <Link to="/page/ms-surgeon">MS SURGEON</Link>
              </li>

              <li className="nav-link">
                <Link to="/page/gastro">GASTRO</Link>
              </li>

              <li className="nav-link">
                <Link to="/page/gynaic">GYNAIC</Link>
              </li>

              <li className="nav-link">
                <Link to="/page/ortho">ORTHO</Link>
              </li>

              <li className="nav-link">
                <Link to="/page/ent">ENT</Link>
              </li>

              <li className="nav-link">
                <Link to="/page/thirumala-lab">THIRUMALA LAB</Link>
              </li>


              <li className="nav-link">
                      <a href="#">
                        OUR DOCTORS
                        <i className="fas fa-caret-down" />
                      </a>
                      <div className="dropdown">
                        <ul className="ul-nav2">
                        <li className="dropdown-link">
                            <Link to="/page/dr.atchiyya-naidu">Dr. K. Atchiyya Naidu</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/page/dr-sudhakar-rao">Dr. K. Sudhakar Rao</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/page/dr-gunna-sriharsha">Dr. Gunna Sriharsha</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/page/dr-rajani-balaga">Dr. Rajani Balaga</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/page/dr-satyaswaroop">Dr. M. Satyaswaroop</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/page/dr-utika-dinesh">Dr. Tutika Dinesh K</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/page/dr-sunil-raviraj">Dr. Sunil Raviraj</Link>
                          </li>
                        </ul>
                      </div>
                    </li>

                    

              {/* About us */}
              <li className="nav-link">
                <a href="#">
                  ABOUT US
                  <i className="fas fa-caret-down" />
                </a>
                <div className="dropdown">
                  <ul className="ul-nav2">
                    <li className="dropdown-link">
                      <Link to="/Aboutclinic">About Hospital</Link>
                    </li>

                    <li className="dropdown-link">
                      <Link to="/Reviews">Reviews</Link>
                    </li>

                    <li className="dropdown-link">
                      <Link to="/Contact">Contact us</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-link">
                <Link to="/Gallery">Gallery</Link>
              </li>
            </ul>
          </div>
          <div id="main1">
            <button className="openbtn" onClick={openNav}>
              ☰ TMC’S TIRUMALA HOSPITAL's Hormone Clinic
            </button>
          </div>
        </>
      ) : (
        <div className="headerSEct">
          <div className={routeMatched ? "headerGallery" : "headerHome"}>
            <div className="">
              <div className="nav-btn">
                <div className="nav-links">
                  <ul className="ul-nav1">
                    <li className="nav-link">
                      <Link to="/">
                        <i
                          className="fa fa-home"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </Link>
                    </li>

                    {/* lap */}
                    <li className="nav-link">
                      <Link to="/page/ms-surgeon">MS SURGEON</Link>
                    </li>

                    <li className="nav-link">
                      <Link to="/page/gastro">GASTRO</Link>
                    </li>

                    <li className="nav-link">
                      <Link to="/page/gynaic">GYNAIC</Link>
                    </li>

                    <li className="nav-link">
                      <Link to="/page/ortho">ORTHO</Link>
                    </li>

                    <li className="nav-link">
                      <Link to="/page/ent">ENT</Link>
                    </li>

                    <li className="nav-link">
                      <Link to="/page/thirumala-lab">THIRUMALA LAB</Link>
                    </li>

                    {/* end lap */}

                    <li className="nav-link">
                      <a href="#">
                        OUR DOCTORS
                        <i className="fas fa-caret-down" />
                      </a>
                      <div className="dropdown">
                        <ul className="ul-nav2">
                        <li className="dropdown-link">
                            <Link to="/page/dr.atchiyya-naidu">Dr. K. Atchiyya Naidu</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/page/dr-sudhakar-rao">Dr. K. Sudhakar Rao</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/page/dr-gunna-sriharsha">Dr. Gunna Sriharsha</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/page/dr-rajani-balaga">Dr. Rajani Balaga</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/page/dr-satyaswaroop">Dr. M. Satyaswaroop</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/page/dr-utika-dinesh">Dr. Tutika Dinesh K</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/page/dr-sunil-raviraj">Dr. Sunil Raviraj</Link>
                          </li>
                        </ul>
                      </div>
                    </li>

                    {/* About us */}
                    <li className="nav-link">
                      <a href="#">
                        ABOUT US
                        <i className="fas fa-caret-down" />
                      </a>
                      <div className="dropdown">
                        <ul className="ul-nav2">
                          <li className="dropdown-link">
                            <Link to="/Aboutclinic">About Hospital</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/Reviews">Reviews</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/Contact">Contact us</Link>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li className="nav-link">
                      <Link to="/Gallery">Gallery</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Reviews" element={<Reviews />} />
        <Route path="/Gallery" element={<ImageLightbox />} />
        <Route path="/Aboutclinic" element={<Aboutclinic />} />
        <Route path="/page/:pagename" element={<Dynamic_page />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
