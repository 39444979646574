import React from "react";
import Facebook from "../assets/facebook.png";
import Instagram from "../assets/instagram.png";
import LinkedIn from "../assets/linkedin.png";
import Youtube from "../assets/youtube.png";
import logo from "../assets/logo.png";
import { BrowserRouter as Router, Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 footer-contact">
                <Link to="/">
                  {" "}
                  <img src={logo} height={50} />
                </Link>
                <p className="text-white">
                  Opp. Rythu Bazar, Ilisipuram Junction
                  <br /> Srikakulam - 532001.
                  <br />
                  <strong className="text-white">Reciption:</strong> +91
                  7581544797
                  <br />
                  <strong className="text-white">Landline:</strong> 08942-294797
                  <br />
                  <strong className="text-white">
                    Medical Center Reciption 1:
                  </strong>{" "}
                  08942-278484
                  <br />
                  <strong className="text-white">
                    Medical Center Reciption 2:
                  </strong>{" "}
                  +91 8886717486
                  <br />
                  <strong className="text-white">Email:</strong>{" "}
                  tmc.srikakulam@gmail.com
                  <br />
                </p>
              </div>
              <div className="col-lg-4 col-md-6 footer-links">
                <h4 className="text-white">Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="/" className="text-white">
                      Home
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="/page/ms-surgeon" className="text-white">
                      MS SURGEON
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="/page/gastro" className="text-white">
                      GASTRO
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="/page/gynic" className="text-white">
                      GYNAIC
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="/page/ortho" className="text-white">
                      ORTHO
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="/page/ent" className="text-white">
                      ENT
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 footer-links">
                <ul>
                 
                <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="Contact" className="text-white">
                      Contact us
                    </a>
                  </li>

                  <li>
                    <div className="container d-lg-flex py-4">
                      <div className="social-links text-center text-lg-right pt-3 pt-lg-0">
                        <a href="#" target="_blank">
                          <img style={{ height: "25px" }} src={Youtube} />
                        </a>
                        <a
                          href="https://www.facebook.com/profile.php?id=100095208955713"
                          target="_blank"
                        >
                          <img style={{ height: "25px" }} src={Facebook} />
                        </a>
                        <a
                          href="https://www.instagram.com/tmcs/"
                          target="_blank"
                        >
                          <img style={{ height: "25px" }} src={Instagram} />
                        </a>
                        <a
                          href="https://www.linkedin.com/in/dr-padmanabha-varma-b18278287/"
                          target="_blank"
                        >
                          <img style={{ height: "25px" }} src={LinkedIn} />
                        </a>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="me-lg-auto text-center text-lg-start text-white">
                      <div className="copyright">
                        © Copyright{" "}
                        <strong>
                          <span>TMC’S TIRUMALA HOSPITAL</span>
                        </strong>
                        . All Rights Reserved
                      </div>
                      <div className="credits text-white">
                        Designed by <a href="#" className="text-white">SRM Software Solutions</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer */}
    </div>
  );
}

export default Footer;
