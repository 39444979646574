import logo from "../assets/logo.png";
import CallIcon from "@mui/icons-material/Call";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import bannerLogo from "../assets/banner-logo.jpg"
import Facebook from "../assets/facebook.png";
import Instagram from "../assets/instagram.png";
import LinkedIn from "../assets/linkedin.png";
import Youtube from "../assets/youtube.png";
import { API_URL } from "./constants";

function Header() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [emptyData, setEmptyData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    selected_date: "",
    selected_time: "",
    reason: "",
    message: "",
  });
  const [formData, setFormData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(`${API_URL + "contact"}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setFormData(emptyData);
        handleClose(true);
      })
      .catch((err) => {
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  return (
    <div>
      <ToastContainer />

      {/* <Router> */}
      <div className="top-header-section">
        <div className="container_view">
          <div className="headerSection">
            <div className="logo_Section">
              <Link to="/">
                {" "}
                <img src={logo} height={90} />
              </Link>
            </div>
            <div>
            <img src={bannerLogo} height={90} />
            </div>

            <div className="contact_section">
              <div className="callsection">
                <div className="phoneImg">
                  <CallIcon className="phonewidth" />
                </div>
                <div className="callus">
                  <h5 className="py-0 my-0">Call Us :+91 7581 544 797 <br/>&nbsp;&nbsp;24/7&nbsp;&nbsp; : +91 8096 254 555</h5>
                </div>
              </div>

              <div className="bookappointment">
                <Button
                  variant="contained"
                  className="radiusBtn"
                  onClick={handleShow}
                >
                  Book Appointment
                </Button>

                <div className="social-links text-center text-lg-right pt-3 pt-lg-0">
                  <a href="#" className="m-1" target="_blank">
                    <img src={Youtube} style={{ height: "25px" }} />
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=100095208955713"
                    target="_blank"
                  >
                    <img
                      src={Facebook}
                      className="m-1"
                      style={{ height: "25px" }}
                    />
                  </a>
                  <a href="https://www.instagram.com/tmcs/" target="_blank">
                    <img
                      src={Instagram}
                      className="m-1"
                      style={{ height: "25px" }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/dr-padmanabha-varma-b18278287/"
                    target="_blank"
                  >
                    <img
                      src={LinkedIn}
                      className="m-1"
                      style={{ height: "25px" }}
                    />
                  </a>
                </div>
              </div>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Please Book Appointment </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="formSubmit">
                    <p>We Are Ready To Help You With A Smile!</p>

                    <form onSubmit={handleSubmit}>
                      <div className="col-md-12">
                        <input
                          type="text"
                          placeholder="Fullname*"
                          name="fullname"
                          id="fullname"
                          value={formData ? formData.fullname : ""}
                          onChange={handleChange}
                          className="form-control mb-3"
                        />
                      </div>

                      <div className="col-md-12">
                        <input
                          type="number"
                          placeholder="Mobile*"
                          name="mobile"
                          id="mobile"
                          value={formData ? formData.mobile : ""}
                          onChange={handleChange}
                          className="form-control mb-3"
                        />
                      </div>

                      <div className="col-md-12">
                        <input
                          type="email"
                          placeholder="Email*"
                          name="email"
                          id="email"
                          value={formData ? formData.email : ""}
                          onChange={handleChange}
                          className="form-control mb-3"
                        />
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="date"
                            placeholder="Email*"
                            name="selected_date"
                            id="selected_date"
                            value={formData ? formData.selected_date : ""}
                            onChange={handleChange}
                            className="form-control mb-3"
                          />
                        </div>

                        <div className="col-md-6">
                          <input
                            type="time"
                            placeholder="Email*"
                            name="selected_time"
                            id="selected_time"
                            value={formData ? formData.selected_time : ""}
                            onChange={handleChange}
                            className="form-control mb-3"
                          />
                        </div>
                      </div>
                      <select
                        className="form-control mb-3"
                        name="reason"
                        id="reason"
                        onChange={handleChange}
                        value={formData ? formData.reason : ""}
                      >
                        <option>Select Problem</option>
                        <option value="MS-SURGEON">MS SURGEON</option>
                              <option value="GASTRO">GASTRO</option>
                              <option value="GYNAIC">GYNAIC</option>
                              <option value="ORTHO">ORTHO</option>
                              <option value="ENT">ENT</option>
                      </select>
                      <div className="my-3">
                        <textarea
                          placeholder="Please tell us your conern in detail"
                          name="message"
                          id="message"
                          value={formData ? formData.message : ""}
                          onChange={handleChange}
                          className="form-control my-3"
                        ></textarea>
                      </div>
                      <div className="my-3 gapivvu">
                        <button type="submit" className="bookapp my-4 w-100">
                          Book Appointment
                        </button>
                      </div>
                    </form>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>

      {/* </Router> */}
    </div>
  );
}

export default Header;
