import React, { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import M1 from "../assets/gallery/(11).JPG";
import M2 from "../assets/gallery/(2).JPG";
import M3 from "../assets/gallery/(3).JPG";
import M4 from "../assets/gallery/(4).JPG";
import M5 from "../assets/gallery/(5).JPG";
import M6 from "../assets/gallery/(6).JPG";
import M7 from "../assets/gallery/(7).JPG";
import M8 from "../assets/gallery/(8).JPG";
import M9 from "../assets/gallery/(8).JPG";
import M11 from "../assets/gallery/(9).JPG";
import M10 from "../assets/gallery/(10).JPG";
import M12 from "../assets/gallery/(12).JPG";
import M13 from "../assets/gallery/(13).JPG";
import bannerOne from "../assets/banner/banner-1.jpeg";
import bannerTwo from "../assets/banner/banner-2.jpeg";
import bannerThree from "../assets/banner/banner-3.jpeg";
import bannerFour from "../assets/banner/banner-4.jpeg";
import Diabetes from "../assets/sri/icons/daibates.png";
import Thyroid from "../assets/sri/icons/Thyroid.png";
import docone from "../assets/docone.jpg";
import doctwo from "../assets/doctwo.jpg";
import Pituitary_tumors from "../assets/sri/icons/Pituitary.jpg";
import Gestational from "../assets/sri/icons/Gestational-Mellitus.png";
import Parathyroid from "../assets/sri/icons/Parathyroid.jpg";
import LipidDisorders from "../assets/sri/icons/Lipid-Disorders.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import loader from "../assets/loader/loader.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { API_URL, ASSETS_URL } from "./constants";

function Home() {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [emptyData, setEmptyData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    selected_date: "",
    selected_time: "",
    reason: "",
    message: "",
  });
  const [formData, setFormData] = useState({});
  const [mainBanners, setMainBanners] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(`${API_URL + "contact"}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setFormData(emptyData);
        handleClose(true);
      })
      .catch((err) => {
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
    axios.get(API_URL + "banners").then((banners) => {
      setMainBanners(banners.data);
    });
  }, []);

  // contact us
  const [emptyDatac, setEmptyDatac] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [formDatac, setFormDatac] = useState({});

  const handleSubmitc = (event) => {
    event.preventDefault();

    axios
      .post(`${API_URL + "contact_request"}`, formDatac, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setFormDatac(emptyDatac);
        handleClose(true);
      })
      .catch((err) => {
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChangec = (e) => {
    const { id, value } = e.target;
    setFormDatac({ ...formDatac, [id]: value });
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <>
      <ToastContainer />
      <Carousel
        showArrows={true}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
      >
        {mainBanners &&
          mainBanners.map((banner, index) => (
            <div key={index}>
              <img
                src={ASSETS_URL + "storage/images/banners/" + banner.image}
                alt={`Slide ${index + 1}`}
              />
            </div>
          ))}
      </Carousel>

      {/* End Hero */}
      <main id="main">
        {/* ======= Why Us Section ======= */}
        <section id="why-us" className="why-us">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-5" data-aos="fade-up">
                <div className="content">
                  <h3>Why choose TMC's Hospital</h3>
                  <p>
                    At TMC's Tirumala Hospital, your health and well-being are
                    our top priorities. Here are two key reasons why you should
                    consider choosing us for your healthcare needs:
                  </p>
                  <p>
                    <strong>Comprehensive Care:</strong> Benefit from a wide
                    range of medical services, cutting-edge technology, and
                    specialized departments, ensuring that we can address
                    diverse healthcare needs all in one place.
                  </p>
                  <p>
                    <strong>Expert Medical Team:</strong> Our highly skilled and
                    experienced healthcare professionals, along with the latest
                    advancements in medical technology, ensure that you receive
                    the best possible care, from accurate diagnoses to effective
                    treatments.
                  </p>
                  <p>
                    We are committed to providing quality, patient-centric care
                    and look forward to being your trusted healthcare partner.
                  </p>
                  <div className="text-center">
                    <a href="#" className="more-btn">
                      Learn More <i className="bx bx-chevron-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 d-flex">
                <div className="icon-boxes d-flex flex-column justify-content-center">
                  <div className="row">
                    <div
                      className="col-xl-3 d-flex align-items-stretch"
                      data-aos="fade-up"
                      data-aos-delay={100}
                    >
                      <div className="icon-box mt-4 mt-xl-0">
                        <i className="bx bx-receipt" />
                        <img src={Diabetes} width={90} />
                        <h4 className="yellowfb800d">100000+</h4>
                        <p className="minht">MS Surgeon Patients</p>
                        <a href="#">
                          <i className="fa fa-arrow-right arrowright" />
                        </a>
                      </div>
                    </div>
                    <div
                      className="col-xl-3 d-flex align-items-stretch"
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <div className="icon-box mt-4 mt-xl-0">
                        <i className="bx bx-cube-alt" />
                        <img src={Thyroid} width={90} />
                        <h4 className="yellowfb800d">1000+</h4>
                        <p className="minht">Gastro Patients</p>
                        <a href="#">
                          <i className="fa fa-arrow-right arrowright" />
                        </a>
                      </div>
                    </div>
                    <div
                      className="col-xl-3 d-flex align-items-stretch"
                      data-aos="fade-up"
                      data-aos-delay={300}
                    >
                      <div className="icon-box mt-4 mt-xl-0">
                        <i className="bx bx-images" />
                        <img src={Pituitary_tumors} width={90} />
                        <h4 className="yellowfb800d">50 +</h4>
                        <p className="minht">Gynaic Patients</p>
                        <a href="#">
                          <i className="fa fa-arrow-right arrowright" />
                        </a>
                      </div>
                    </div>

                    <div
                      className="col-xl-3 d-flex align-items-stretch"
                      data-aos="fade-up"
                      data-aos-delay={300}
                    >
                      <div className="icon-box mt-4 mt-xl-0">
                        <i className="bx bx-images" />
                        <img src={Gestational} width={90} />
                        <h4 className="yellowfb800d">200 +</h4>
                        <p className="minht">Ortho & ENT Patients</p>
                        <a href="#">
                          <i className="fa fa-arrow-right arrowright" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Why Us Section */}

        {/* our services */}
        <div className="container section-title">
          <h2 className="text-center">Our treatments</h2>
          <div className="row">
            <div className="col-md-6">
              <h4 className="text-center mt-4 mb-3">
                Gynaecology & Obstetrics
              </h4>
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>SL. NO</th>
                    <th>PARTICULAR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>NORMAL DELIVERY</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>FORCEPS/EPISIOTOMY DELIVER</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>LSCS CHARGES</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>D & C</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>LAP/DIAG HYSTEROSCOPY</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>OVARIAN CYSTECTOMY</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>ABD HYSTERECTOMY / 1AH</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>LAPAROTOMY FOR ECTOPIC</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>VH WITH PFR</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>VH (VAGINA HYSTERECTOMY)</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>LAVH VAGINAL HYSTERECTOMY</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>LAPAROSCOPIC STERILIZATION</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>DIAGNOSTIC LAPROSCOPY WITH PCO DRILLING</td>
                  </tr>
                </tbody>
              </table>

              <h4 className="text-center mt-4">Orthopaedic Surgery</h4>
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>SL. NO</th>
                    <th>PARTICULAR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>POP UNDER SHORT GA</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>CLOSED REDUCTION UNDER GA</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>ORIF FOR MAJO BONE</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>ORIF FOR MINOR BONE</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>K WIRE FIXATION WITH BEDRIDEMENT</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>ORIF PLATE FIXATION</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>HIP REPLACEMENT</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>REMOVAL OF PLATES/SCREW/IMPLANTS</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>DISCECTOMY</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>ARTHROSCOPY</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>ACL TEAR REPAIR /IMPLANTS</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>SPINAL FIXATION</td>
                  </tr>
                </tbody>
              </table>

              <h4 className="text-center mt-4">ENT</h4>
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>SL. NO</th>
                    <th>PARTICULAR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>SEPTOPLASTY</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>FESS</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>TURBINECTOMY</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>TONSILLECTOMY</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>ADENOTONSILLECTOMY</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>MASTOIDECTOMY</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>RHINOPLASTY</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>TYMPANOPLASTY</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-md-6">
              <h4 className="text-center mt-4">
                General Surgery & Surgical GE
              </h4>
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>SL. NO</th>
                    <th>PARTICULAR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>OPEN APPENDICECTOMY</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>LAP. APPENDICETOMY</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>HERNIOPLASTY</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>INGUINAL HERNIA</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>UMBILICAL HERNIA</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>HERNIORRAPHY</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>OPEN CHOLECYSTECTOMY</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>LAP. CHOLECYSTECTOMY</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>MASTECTOMY SIMPLE</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>MASTECTOMY RADICAL</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>FISSURE DILATATION</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>FISTULA LOW</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>FISTULA HIGH</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>THYROIDECTOMY HEMI</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>THYRODECTOMY TOTAL</td>
                  </tr>
                  <tr>
                    <td>16</td>
                    <td>LIPOM EXPLSION</td>
                  </tr>
                  <tr>
                    <td>17</td>
                    <td>AP RESECTION</td>
                  </tr>
                  <tr>
                    <td>18</td>
                    <td>HVDROCELE</td>
                  </tr>
                  <tr>
                    <td>19</td>
                    <td>HEAMORRHOIDECTOMY</td>
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>GASTRECTOMY</td>
                  </tr>
                  <tr>
                    <td>21</td>
                    <td>HEMICOLECTOMY</td>
                  </tr>
                  <tr>
                    <td>22</td>
                    <td>HIATUS HERNIA REPAIR</td>
                  </tr>
                  <tr>
                    <td>23</td>
                    <td>VERICEAL BANDING</td>
                  </tr>
                  <tr>
                    <td>24</td>
                    <td>INT. PERFORATION (6-DAY)</td>
                  </tr>
                  <tr>
                    <td>25</td>
                    <td>INT. OBSTRUCTION (6DAY)</td>
                  </tr>
                  <tr>
                    <td>26</td>
                    <td>LAPAROTOM</td>
                  </tr>
                  <tr>
                    <td>27</td>
                    <td>DU CLOSURE</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="container mt-5 section-title">
          <h2 className="text-center mb-5">TEST's List</h2>
          <div className="row">
            <div className="col-md-6">
              <h4>Diagnostic Tests</h4>
              <table className="table table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>Test</th>
                    <th>Test</th>
                    <th>Test</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>OP - CONSULTATION (FOR ALL)</td>
                    <td>S.CALCIUM</td>
                    <td>CUE</td>
                  </tr>
                  <tr>
                    <td>CBC</td>
                    <td>S.PHOSPHORUS</td>
                    <td>URINE ROUTINE</td>
                  </tr>
                  <tr>
                    <td>HEMOGRAM/ CBP</td>
                    <td>L.F.T</td>
                    <td>URINE SUGAR</td>
                  </tr>
                  <tr>
                    <td>TRBC</td>
                    <td>S.BILIRUBIN-T</td>
                    <td>URINE ALBUMIN</td>
                  </tr>
                  <tr>
                    <td>TWBC</td>
                    <td>S.BILIRUBIN-D</td>
                    <td>URINE MICRO</td>
                  </tr>
                  <tr>
                    <td>DC</td>
                    <td>S.BILIRUBIN-IN</td>
                    <td>U- PREGNANCY</td>
                  </tr>
                  <tr>
                    <td>PLATELET COUNT</td>
                    <td>S.G.O.T</td>
                    <td>URINE REACTION</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-md-6">
              <h4>Biochemical Tests</h4>
              <table className="table table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>Test</th>
                    <th>Test</th>
                    <th>Test</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>S.PROTEIN</td>
                    <td>S.GLOBULIN</td>
                    <td>S.UREA</td>
                  </tr>
                  <tr>
                    <td>S.ALBUMIN</td>
                    <td>S.A/G RATIO</td>
                    <td>S.CREATININE</td>
                  </tr>
                  <tr>
                    <td>S.URIC ACID</td>
                    <td>LH</td>
                    <td>CPK</td>
                  </tr>
                  <tr>
                    <td>S.ELECTROLYTES</td>
                    <td>PROLACTIN</td>
                    <td>ACETIC & ADA</td>
                  </tr>
                  <tr>
                    <td>S.SODIUM</td>
                    <td>TROP-T</td>
                    <td>ACETIC ANALYSIS</td>
                  </tr>
                  <tr>
                    <td>S.POTASSIUM</td>
                    <td>BLOOD C/S</td>
                    <td>FNAC</td>
                  </tr>
                  <tr>
                    <td>S.CHLORIDE</td>
                    <td>TB-SCAN</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h4>Hormone Tests</h4>
              <table className="table table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>Test</th>
                    <th>Test</th>
                    <th>Test</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>S.PROTEIN</td>
                    <td>S.GLOBULIN</td>
                    <td>S.UREA</td>
                  </tr>
                  <tr>
                    <td>S.ALBUMIN</td>
                    <td>S.A/G RATIO</td>
                    <td>S.CREATININE</td>
                  </tr>
                  <tr>
                    <td>S.URIC ACID</td>
                    <td>LH</td>
                    <td>CPK</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-md-6">
              <h4>Other Tests</h4>
              <table className="table table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>Test</th>
                    <th>Test</th>
                    <th>Test</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>ANA</td>
                    <td>TESTOSTERONE-T</td>
                    <td>S.ESTRADIOL</td>
                  </tr>
                  <tr>
                    <td>AFP(Alfa Feto Protein)</td>
                    <td>TESTOSTERONE-F</td>
                    <td>HP-ELECTROPHORESIS</td>
                  </tr>
                  <tr>
                    <td>PSA-T</td>
                    <td>PROGESTERONE</td>
                    <td>TORCH 8</td>
                  </tr>
                  <tr>
                    <td>PSA-F</td>
                    <td>BETA-HCG</td>
                    <td>TORCH 10</td>
                  </tr>
                  <tr>
                    <td>S.LIPASE</td>
                    <td>Anti ds DNA</td>
                    <td>Anti CCP Antibodies</td>
                  </tr>
                  <tr>
                    <td>ANY X-RAY AP</td>
                    <td>AP-LAT</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* end */}

        <section id="about" className="about section-bg">
          <div className="container">
            <div className="row text-center">
              <h3 className="font-weight-bold">
                Effective treatment for diabetes and Endocrine
                <span className="crls"> disorders requires a holistic </span>
                approach that considers not only the physical symptoms, but also
                the emotional and psychological well-being of the patient.
              </h3>

              <div className="container my-4">
                <div className="row">
                  <div
                    className="col-md-3 col-xs-12"
                    data-aos="fade-up"
                    data-aos-delay={100}
                  >
                    <img src={M1} width={200} className="w-100" />
                  </div>

                  <div
                    className="col-md-3 col-xs-12"
                    data-aos="fade-up"
                    data-aos-delay={300}
                  >
                    <img src={M2} width={200} className="w-100" />
                  </div>

                  <div
                    className="col-md-3 col-xs-12"
                    data-aos="fade-up"
                    data-aos-delay={500}
                  >
                    <img src={M5} width={200} className="w-100" />
                  </div>

                  <div
                    className="col-md-3 col-xs-12"
                    data-aos="fade-up"
                    data-aos-delay={1000}
                  >
                    <img src={M7} width={200} className="w-100" />
                  </div>
                </div>
              </div>
            </div>
            <section id="about" className="about section-bg">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-xs-12 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                    <h2 data-aos="fade-up">
                      Few Words About
                      <span className="blueclr">
                        &nbsp;&nbsp;TMC’S TIRUMALA HOSPITAL
                      </span>{" "}
                      &nbsp; in Srikakulam
                    </h2>
                    <p className="mt-3">
                      At TMC's Tirumala Hospital, we are devoted to offering a
                      holistic healthcare experience. Our team of dedicated
                      professionals is committed to addressing the diverse needs
                      of our patients with compassion and expertise.
                    </p>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="icon-box col-md-6" data-aos="fade-up">
                      <div className="icon">
                        <img src={Diabetes} width={70} />
                      </div>
                      <h4 className="title">
                        <a href>Diabetes mellitus</a>
                      </h4>
                      <p className="description small">
                        Diabetes mellitus is characterised by an inability to
                        secrete insulin which is needed by the body to convert
                        glucose into energy. The three main types of diabetes –
                        type 1, type 2 and gestational are all defined as
                        metabolic disorders that affect the way the body
                        metabolises or uses digested food to make glucose.{" "}
                      </p>
                    </div>

                    <div className="icon-box col-md-6" data-aos="fade-up">
                      <div className="icon">
                        <img src={Thyroid} width={70} />
                      </div>
                      <h4 className="title">
                        <a href>Thyroid disorders</a>
                      </h4>

                      <p className="description small">
                        The thyroid gland is located in the neck just below the
                        Adam’s apple and produces hormones that play a key role
                        in regulating blood pressure, body temperature, heart
                        rate, metabolism and the reaction of the body to other
                        hormones. Condition including hypothyroidism,
                        hyperthyroidism, thyroid adenomas and thyroid cancer can
                        occur due to fluctuation in the amount of hormones
                        released by the thyroid gland.
                      </p>
                    </div>
                    <div
                      className="icon-box col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={100}
                    >
                      <div className="icon">
                        <img src={Pituitary_tumors} width={70} />
                      </div>
                      <h4 className="title">
                        <a href>Pituitary disorders</a>
                      </h4>
                      <p className="description">
                        The pituitary gland is located just off the hypothalamus
                        at the base of the brain. This gland regulates the
                        functions of other glands, as well as growth and several
                        body functions. Overactivity and underactivity of the
                        pituitary gland can cause a range of conditions like
                        acromegaly, empty sella syndrome and hypopituitarism.
                      </p>
                    </div>
                    <div
                      className="icon-box col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <div className="icon">
                        <img src={Parathyroid} width={70} />
                      </div>
                      <h4 className="title">
                        <a href>
                          Metabolic Bone, Calcium and Parathyroid Disorders
                        </a>
                      </h4>
                      <p className="description">
                        The parathyroid glands regulate the amount of calcium
                        and phosphorous in the blood. Metabolic bone disorders
                        are disorders of bone strength such as osteoporosis,
                        osteomalacia, Paget’s disease and parathyroid disorders.{" "}
                      </p>
                    </div>

                    <div
                      className="icon-box col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <div className="icon">
                        <img src={LipidDisorders} width={70} />
                      </div>
                      <h4 className="title">
                        <a href>Lipid Disorders</a>
                      </h4>
                      <p className="description">
                        Lipids or lipoproteins are the fats circulating in the
                        bloodstream like low-density lipoproteins (LDL),
                        high-density lipoproteins (HDL) and triglycerides. Lipid
                        disorders can contribute to the formation of plaque
                        build-up in your arteries which is linked to increased
                        risk of heart attack and stroke.{" "}
                      </p>
                    </div>

                    <div
                      className="icon-box col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <div className="icon">
                        <img src={Gestational} width={70} />
                      </div>
                      <h4 className="title">
                        <a href>Gonadal and Menstrual Disorders</a>
                      </h4>
                      <p className="description">
                        Males and females have different gonads; testes and
                        ovaries respectively. Gonadal and menstrual disorders
                        stem primarily from hormone dysfunctions, the ovaries
                        and testes produce many of the same hormones, but in
                        different amounts. Menstrual disorders can include
                        abnormally early or late onset of puberty, very light
                        periods, very heavy periods and irregular or absent
                        periods. They can also affect ovulation, including
                        increasing the risk of ovarian cyst development,
                        problems during pregnancy and the early onset of
                        menopause.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        {/* End About Section */}

        <div>
          <>
            {/* ======= Services Section ======= */}
            <section id="services" className="services services">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h2>Our specialized Hospital</h2>
                  <p>
                    We provide world class comprehensive one stop solution to
                    all hormone related problems. This is your only destination
                    for all diabetes, thyroid, PCOD, growth and all hormone
                    related problems.
                  </p>
                  <p>
                    Our specialised Hospital include thyroid, gestational
                    diabetes mellitus, dietician, obesity Hospital, pituitary
                    Hospital, continuous glucose monitoring systems and insulin
                    pumps.
                  </p>
                </div>

                <div className="row">
                  <div
                    className="col-lg-4 col-md-6 icon-box"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div className="icon">
                      <i className="fas fa-heartbeat" />
                    </div>
                    <h4 className="title">
                      <a href="">Diabetes Hospital</a>
                    </h4>
                    <p className="description">
                      The goal of the Diabetes Hospital is to encourage patients
                      to receive early specialty care and education – getting
                      them on the right track with their diabetes management
                      before returning them to their primary care physicians for
                      ongoing management. We also provide you with easy access
                      to renowned specialities who are often involved in
                      diabetes care such as cardiologists, ophthalmologists,
                      nephrologists, and dermatologists.{" "}
                    </p>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 icon-box"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <div className="icon">
                      <i className="fas fa-pills" />
                    </div>
                    <h4 className="title">
                      <a href="">Endocrine Surgery</a>
                    </h4>
                    <p className="description">
                      This Hospital provides surgical care of thyroid,
                      parathyroid, adrenal, endocrine pancreas as well as
                      neuroendocrine and other tumours metastatic to the liver.
                      Our endocrine surgery team has the unique expertise for
                      complex endocrine problems including patients requiring
                      re-operative surgery or those with complex thyroid cancers
                      and endocrine malignancies.
                    </p>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 icon-box"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <div className="icon">
                      <i className="fas fa-notes-medical" />
                    </div>
                    <h4 className="title">
                      <a href="">Obesity Hospital</a>
                    </h4>
                    <p className="description">
                      With sedentary lifestyles and hectic schedules, it is
                      quite common to not take care of what and how much people
                      eat, and hence, a lot of people fall prey to obesity. The
                      Hospital has been started to spread awareness and also to
                      treat people suffering from the disorder.{" "}
                    </p>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 icon-box"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div className="icon">
                      <i className="fas fa-dna" />
                    </div>
                    <h4 className="title">
                      <a href="">Paediatric Endocrinology Hospital</a>
                    </h4>
                    <p className="description">
                      The Hospital takes care of all the hormonal disorders in
                      children such as juvenile diabetes, growth and puberty
                      disorders, and sex differentiation. The Hospital is
                      equipped with required technology and expertise to provide
                      comprehensive care to patients.{" "}
                    </p>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 icon-box"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <div className="icon">
                      <i className="fas fa-wheelchair" />
                    </div>
                    <h4 className="title">
                      <a href="">Bone health and osteoporosis Hospital</a>
                    </h4>
                    <p className="description">
                      The density of bones can decrease to changes in hormonal
                      changes, especially women, which can lead to fragility
                      fractures, bone deformities and serious disability. The
                      Hospital was initiated to ensure patients get treated with
                      great care, and are able to resume their lifestyle without
                      any pain.{" "}
                    </p>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 icon-box"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <div className="icon">
                      <i className="fas fa-notes-medical" />
                    </div>
                    <h4 className="title">
                      <a href="">Thyroid Hospital</a>
                    </h4>
                    <p className="description">
                      We have designed our services so that all of the
                      specialists you need including endocrinologists, endocrine
                      surgeons and a complete nursing staff, work together under
                      one roof as a team to accurately diagnose and treat all
                      forms of thyroid disorders. This high-volume centre have
                      experts working hand-in-hand with your primary care
                      physician to help you receive the most seamless care for
                      your condition possible on an ongoing basis.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            {/* End Services Section */}
          </>

          {/* ======= Appointment Section ======= */}
          <section id="appointment" className="appointment section-bg">
            <div className="container">
              <div className="section-title">
                <h2>Make an Appointment</h2>
                <p>
                  Waiting is painful. Lessen the waiting time with just one
                  phone call. Call us at +91 7581 544 797
                </p>
              </div>
              <ToastContainer />
              <form onSubmit={handleSubmit} className="php-email-form">
                <div className="row">
                  <div className="col-md-4 form-group">
                    <input
                      type="text"
                      name="fullname"
                      className="form-control"
                      id="fullname"
                      value={formData ? formData.fullname : ""}
                      onChange={handleChange}
                      placeholder="Your Name"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      value={formData ? formData.email : ""}
                      onChange={handleChange}
                      placeholder="Your Email"
                      data-rule="email"
                      data-msg="Please enter a valid email"
                    />
                    <div className="validate" />
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input
                      type="number"
                      className="form-control"
                      name="mobile"
                      id="mobile"
                      value={formData ? formData.mobile : ""}
                      onChange={handleChange}
                      placeholder="Your Phone"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <div className="validate" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 form-group mt-3">
                    <label id="c_date">Date</label>
                    <input
                      type="date"
                      name="date"
                      min={disablePastDate()}
                      className="form-control datepicker"
                      id="selected_date"
                      value={formData ? formData.selected_date : ""}
                      onChange={handleChange}
                      placeholder="Appointment Date"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <div className="validate" />
                  </div>

                  <div className="col-md-4 form-group mt-3">
                    <label id="c_time">Time</label>
                    <input
                      type="time"
                      name="time"
                      className="form-control datepicker"
                      id="selected_time"
                      value={formData ? formData.selected_time : ""}
                      onChange={handleChange}
                      placeholder="Appointment time"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <div className="validate" />
                  </div>

                  <div className="col-md-4 form-group mt-3">
                    <select
                      name="department"
                      id="reason"
                      onChange={handleChange}
                      value={formData ? formData.reason : ""}
                      className="form-select"
                    >
                      <option>Select Problem</option>
                      <option value="MS-SURGEON">MS SURGEON</option>
                              <option value="GASTRO">GASTRO</option>
                              <option value="GYNAIC">GYNAIC</option>
                              <option value="ORTHO">ORTHO</option>
                              <option value="ENT">ENT</option>
                    </select>
                    <div className="validate" />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows={5}
                    id="message"
                    value={formData ? formData.message : ""}
                    onChange={handleChange}
                    placeholder="Message (Optional)"
                    defaultValue={""}
                  />
                  <div className="validate" />
                </div>
                <div className="mb-3">
                  <div className="loading">Loading</div>
                  <div className="error-message" />
                  <div className="sent-message">
                    Your appointment request has been sent successfully. Thank
                    you!
                  </div>
                </div>
                <div className="text-center">
                  {loading ? (
                    <img src={loader} style={{ height: "100px" }} />
                  ) : (
                    <button type="submit">Make an Appointment</button>
                  )}
                </div>
              </form>
            </div>
          </section>
          {/* End Appointment Section */}
          {/* ======= Departments Section ======= */}
          <section id="departments" className="departments">
            <div className="container">
              <div className="section-title">
                <h2>Departments</h2>
              </div>
              <div className="row gy-4">
                <div className="col-lg-3">
                  <ul className="nav nav-tabs flex-column">
                    <li className="nav-item">
                      <a
                        className="nav-link active show"
                        data-bs-toggle="tab"
                        href="#tab-1"
                      >
                        {" "}
                        <i className="fas fa-heartbeat" /> MS Surgeon
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#tab-2"
                      >
                        {" "}
                        <i className="fas fa-heart" /> Gastro
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#tab-3"
                      >
                        <i className="fas fa-plus-square" /> GYN
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#tab-4"
                      >
                        {" "}
                        <i className="fas fa-heartbeat" /> ORTHO
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#tab-5"
                      >
                        <i className="fas fa-stethoscope" /> ENT
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-9">
                  <div className="tab-content">
                    <div className="tab-pane active show" id="tab-1">
                      <div className="row gy-4">
                        <div className="col-lg-8 details order-2 order-lg-1">
                          <h3>MS Surgeon</h3>
                          <p className="font-weight-normal">
                            MS Surgeon is a groundbreaking innovation that
                            revolutionizes surgical procedures. Utilizing
                            robotic technology and advanced machine learning
                            algorithms, MS Surgeon ensures unparalleled
                            precision in complex surgeries, ultimately improving
                            patient outcomes.
                          </p>
                        </div>
                        <div className="col-lg-4 text-center order-1 order-lg-2">
                          <img src="" className="img-fluid" alt="MS Surgeon" />
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane" id="tab-2">
                      <div className="row gy-4">
                        <div className="col-lg-8 details order-2 order-lg-1">
                          <h3>Gastro</h3>
                          <p className="font-weight-normal">
                            Gastroenterology is experiencing transformative
                            advancements with innovative technologies and tools.
                            These developments are reshaping the way digestive
                            disorders are diagnosed and treated, offering new
                            possibilities for patient care.
                          </p>
                        </div>
                        <div className="col-lg-4 text-center order-1 order-lg-2">
                          <img src="" className="img-fluid" alt="Gastro" />
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane" id="tab-3">
                      <div className="row gy-4">
                        <div className="col-lg-8 details order-2 order-lg-1">
                          <h3>Gynecology</h3>
                          <p className="font-weight-normal">
                            Gynecology is at the forefront of women's health,
                            with advancements improving care for various
                            reproductive conditions. Discover how technology and
                            expertise collaborate to empower women and enhance
                            their reproductive health.
                          </p>
                        </div>
                        <div className="col-lg-4 text-center order-1 order-lg-2">
                          <img src="" className="img-fluid" alt="Gynecology" />
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane" id="tab-4">
                      <div className="row gy-4">
                        <div className="col-lg-8 details order-2 order-lg-1">
                          <h3>Orthopedics</h3>
                          <p className="font-weight-normal">
                            Orthopedic innovations are transforming
                            musculoskeletal care, offering new solutions for
                            diagnosis, treatment, and rehabilitation. Learn how
                            advancements are improving mobility and overall
                            quality of life for patients.
                          </p>
                        </div>
                        <div className="col-lg-4 text-center order-1 order-lg-2">
                          <img src="" className="img-fluid" alt="Orthopedics" />
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane" id="tab-5">
                      <div className="row gy-4">
                        <div className="col-lg-8 details order-2 order-lg-1">
                          <h3>ENT Innovations</h3>
                          <p className="font-weight-normal">
                            Ear, Nose, and Throat (ENT) specialists are leading
                            innovations that enhance the diagnosis and treatment
                            of conditions affecting these vital sensory organs.
                            Explore the latest technologies reshaping ENT care.
                          </p>
                        </div>
                        <div className="col-lg-4 text-center order-1 order-lg-2">
                          <img src="" className="img-fluid" alt="ENT" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Departments Section */}
          {/* ======= Doctors Section ======= */}
          {/* <section id="doctors" className="doctors">
            <div className="container">
              <div className="section-title">
                <h2>Management</h2>
                <p>Know the Expert Before You Visit Our Hospital</p>
              </div>

              <section className="our-webcoderskull padding-lg">
                <div className="container">
                  <ul className="row team-list">
                    <li className="col-12 col-md-6 col-lg-3">
                      <div className="cnt-block equal-hight">
                        <figure>
                          <img src={docone} className="img-responsive" alt="" />
                        </figure>
                        <h3>
                          <a href="#">RAMESH BABU</a>
                        </h3>
                        <p>MANAGING PARTNER</p>
                        <p>Phone : +91 9848474714</p>
                        <ul className="follow-us clearfix">
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-facebook"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-twitter"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-linkedin"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="col-12 col-md-6 col-lg-3">
                      <div className="cnt-block equal-hight">
                        <figure>
                          <img src={doctwo} className="img-responsive" alt="" />
                        </figure>
                        <h3>
                          <a href="#">MURALI </a>
                        </h3>
                        <p>MANAGING PARTNER</p>
                        <p>+91 8096254555</p>
                        <ul className="follow-us clearfix">
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-facebook"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-twitter"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-linkedin"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </section>
            </div>
          </section> */}
          {/* End Doctors Section */}

          {/* ======= Testimonials Section ======= */}

          {/* ======= Gallery Section ======= */}
          <section id="gallery" className="gallery">
            <div className="container">
              <div className="section-title">
                <h2>Gallery</h2>
                <p>We are providing wonderful treatment with best quality</p>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row g-0">
                <div className="col-lg-3 col-md-4">
                  <div className="gallery-item">
                    <div className="card-body">
                      <a href="Gallery" className="galelry-lightbox">
                        <img src={M1} className="img-fluid rounded" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="gallery-item">
                    <div className="card-body">
                      <a href="Gallery" className="galelry-lightbox">
                        <img src={M3} className="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="gallery-item">
                    <div className="card-body">
                      <a href="Gallery" className="galelry-lightbox">
                        <img src={M4} className="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="gallery-item">
                    <div className="card-body">
                      <a href="Gallery" className="galelry-lightbox">
                        <img src={M5} className="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="gallery-item">
                    <div className="card-body">
                      <a href="Gallery" className="galelry-lightbox">
                        <img src={M6} className="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="gallery-item">
                    <div className="card-body">
                      <a href="Gallery" className="galelry-lightbox">
                        <img src={M7} className="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="gallery-item">
                    <div className="card-body">
                      <a href="Gallery" className="galelry-lightbox">
                        <img src={M8} className="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="gallery-item">
                    <div className="card-body">
                      <a href="Gallery" className="galelry-lightbox">
                        <img src={M9} className="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="gallery-item">
                    <div className="card-body">
                      <a href="Gallery" className="galelry-lightbox">
                        <img src={M10} className="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="gallery-item">
                    <div className="card-body">
                      <a href="Gallery" className="galelry-lightbox">
                        <img src={M11} className="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="gallery-item">
                    <div className="card-body">
                      <a href="Gallery" className="galelry-lightbox">
                        <img src={M12} className="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="gallery-item">
                    <div className="card-body">
                      <a href="Gallery" className="galelry-lightbox">
                        <img src={M13} className="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Gallery Section */}
          {/* ======= Contact Section ======= */}
          <section id="contact" className="contact">
            <div className="container">
              <div className="section-title">
                <h2>Contact</h2>
                <p>you can contact with you're choice</p>
              </div>
            </div>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1178.286005139256!2d83.89757364355147!3d18.305866122532098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3c1518bc53ae73%3A0xcd1785b645edbb7!2sTMC&#39;s%20TIRUMALA%20HOSPITAL%2C%20SRIKAKULAM%20-%20INDIA.!5e0!3m2!1sen!2sin!4v1709689974960!5m2!1sen!2sin"
                width="600"
                height="450"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="container">
              <div className="row mt-5">
                <div className="col-lg-4">
                  <div className="info">
                    <div className="address">
                      <i className="fa fa-map-marker" />
                      <h4>Location:</h4>
                      <p>
                        Opp. Rythu Bazar, Ilisipuram Junction
                        <br /> Srikakulam - 532001.
                      </p>
                    </div>
                    <div className="email">
                      <i className="fa fa-envelope" />
                      <h4>Email:</h4>
                      <p>tmc.srikakulam@gmail.com</p>
                    </div>
                    <div className="phone">
                      <i className="fa fa-phone" />
                      <h4>Call:</h4>
                      <p>+91 7581 544 797</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 mt-5 mt-lg-0">
                  <form onSubmit={handleSubmitc} className="php-email-form">
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          value={formDatac ? formDatac.name : ""}
                          onChange={handleChangec}
                          placeholder="Your Name"
                          required
                        />
                      </div>
                      <div className="col-md-6 form-group mt-3 mt-md-0">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={formDatac ? formDatac.email : ""}
                          onChange={handleChangec}
                          id="email"
                          placeholder="Your Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        value={formDatac ? formDatac.subject : ""}
                        onChange={handleChangec}
                        id="subject"
                        placeholder="Subject"
                        required
                      />
                    </div>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control"
                        name="message"
                        id="message"
                        value={formDatac ? formDatac.message : ""}
                        onChange={handleChangec}
                        rows={5}
                        placeholder="Message"
                        required
                        defaultValue={""}
                      />
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message" />
                      <div className="sent-message">
                        Your message has been sent. Thank you!
                      </div>
                    </div>
                    <div className="text-center">
                      <button className="btn btn-primary">Send Message</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          {/* End Contact Section */}
        </div>
      </main>
      {/* End #main */}
    </>
  );
}

export default Home;
